@font-face {
  font-family: MontserratBold;
  src: url(./fonts/Montserrat-Bold.otf);
}

@font-face {
  font-family: MontserratRegular;
  src: url(./fonts/Montserrat-Regular.otf);
}

@font-face {
  font-family: kanitRegular;
  src: url(./fonts/kanit-regular.otf);
}

@font-face {
  font-family: kanitBold;
  src: url(./fonts/kanit-bold.otf);
}

.wrapper {
  width: 100%;
  z-index: 3;
}
.container {
  width: 95%;
  margin: auto;
  padding: 0 1.5%;
  box-sizing: border-box;
}

.container-header {
  width: 100%;
  margin: auto;
}

.navbar-brand {
  width: 270px;
}

.navbar-brand img {
  width: 100%;
}
.comngsoon {
  height: 74vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comngsoon h1 {
  font-size: 60px;
}
.shafimg2 {
  width: 40%;
  display: flex;
  align-items: center;
}
.shafimg img {
  width: 100%;
}
.banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("images/Component 471.png");
  bottom: -25px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  z-index: -1;
  opacity: 0.9;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  list-style: none;
  float: left;
  padding: 0 0px 0 30px;
}

.nav-link {
  font-size: 18px;
  font-family: kanitRegular;
  line-height: 27px;
  color: #357ce1;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
}
.switch {
  height: 30px;
  width: 79px;
  border-radius: 50px;
  border: 1px solid #0950b5;
  background-color: #0950b5;
  position: relative;
  /* top: 8px; */
}

.switch .light-span {
  left: 1px;
  position: absolute;
  top: 1px;
  width: 39px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: 0.3s ease-in;
}
.switch .light-span svg {
  font-size: 20px;
  /* color: #0950b5; */
}

.switch .dark-span {
  right: 1px;
  position: absolute;
  top: 1px;
  width: 39px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: 0.3s ease-in;
}
.switch .dark-span svg {
  font-size: 20px;
  /* color: #e9f2ff; */
}

.fa-bars {
  color: #0950b5;
}

.i-style {
  border: 1px solid #0950b5;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

ul.navbar-nav li a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

ul.navbar-nav li a:hover {
  color: #93a4bb;
}

.banner {
  width: 100%;
  padding-top: 120px;
  position: relative;
  padding-bottom: 75px;
  z-index: 10;
}

.banner-footer {
  display: flex;
  padding: 10px 0;
  margin-top: 0px;
  position: relative;
  z-index: 10;
}

.banner-footer ul {
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
}

.banner-footer ul li {
  margin: 0px 20px;
  font-family: kanitRegular;
  font-size: 20px;
}
ul.visitbutton {
  background: #e9f2ff;
  width: max-content;
  padding: 0;
  border-radius: 30px;
  margin: 0;
  float: left;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-xl-6.col-lg-6.col-md-6.col-sm-12.col-12 {
  width: 50%;
  /* float: left; */
}
ul.visitbutton li {
  list-style: none;
  float: left;
  padding: 4px 30px;
  border-radius: 20px;
}
ul.visitbutton li a {
  font-size: 18px;
  font-family: KanitRegular;
  color: #0950b5;
  padding: 20px 0;
  text-decoration: none;
}
ul.visitbutton li:first-child {
  background: #0950b5;
  font-weight: lighter !important;
}
ul.visitbutton li:first-child a {
  color: #e9f2ff;
}
.pricebutton {
  width: 100%;
  padding: 120px 0 0 0;
}
.pricebutton h6 {
  font-size: 60px;
  float: left;
  font-family: kanitRegular;
  line-height: 70px;
  color: #0950b5;
  margin: 14px 0 0 0;
  font-weight: lighter;
  width: 100%;
  padding-right: 30%;
  box-sizing: border-box;
}

.pricebutton h6 span {
  font-family: KanitBold;
}

.pricebutton p {
  font-size: 20px;
  font-family: kanitRegular;
  padding: 0 70px 0 0;
  float: left;
  margin: 11px 0 0 0;
  color: #5f6471;
}

.middle-list {
  display: flex;
  align-items: center;
}

.blog-back .container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.blog-back {
  padding: 95px 0px 75px;
}

.blog-img {
  /* background: white; */
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 5px;
  border: 4px solid #0950b5;
  margin: auto;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-div {
  /* background-color: white; */
  width: 90%;
  padding: 60px 10px 40px;
  border-radius: 15px;
  font-size: 14px;
  position: relative;
  top: -35px;
  box-sizing: border-box;
  margin: auto;
  height: 100%;
}
.blog-div a {
  position: absolute;
  bottom: 21px;
  left: 0;
  right: 0;
}
.blog-back .container > div {
  flex: 0 0 20%;
}

.blog-div h3 {
  color: #0950b5;
  font-family: MontserratBold;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-size: 24px;
}


.blog-div p {
  font-family: MontserratRegular;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 45px;
  /* min-height: calc(18vh + 30px); */
}

.blog-div a {
  background-color: #e9f2ff;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
  color: #0950b5;
  font-family: KanitRegular;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  width: 100%;
  max-width: 70%;
  display: table;
  margin: 0 auto;
  font-size: 18px;
}

.blog-div a:hover {
  background-color: #0950b5;
  color: #e9f2ff;
}

.contact {
  background-color: #0950b5;
  text-align: center;
  color: #e9f2ff;
  font-family: kanit;
  padding: 80px 40px 90px;
}

.main-para {
  margin-bottom: 20px;
  font-family: kanitregular;
  font-size: 40px;
  color: #e9f2ff;
  margin-top: 0;
}

.contact-div {
  line-height: 10px;
  padding: 15px;
  width: 100%;
}
.soclcen {
  width: 40%;
  margin: 0 auto;
}

.contact-div .total-follow {
  font-family: MontserratBold;
  font-size: 36px;
  line-height: 1.4;
  margin: 14px 0;
}

.contact-div .follow-txt {
  font-family: MontserratRegular;
  font-size: 16px;
  margin-bottom: 40px;
}

.contact-div a {
  background-color: #e9f2ff;
  color: #0950b5 !important;
  border: none;
  border-radius: 20px;
  padding: 8px 25px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  font-family: KanitRegular;
  text-decoration: none;
  white-space: nowrap;
}

.contact-div a:hover {
  background-color: #357ce1;
  color: white !important;
}

.logo-contact {
  background-color: rgb(233, 242, 255);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  color: rgb(9, 80, 181);
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  transition: all 0.5s ease;
}
.logo-contact svg {
  font-size: 36px;
}

.logo-contact:hover {
  background: rgb(9, 80, 181);
  box-shadow: 0 0 10px #0000003d;
}

.logo-contact:hover svg {
  color: rgb(233, 242, 255);
}
.partner {
  /* background-color: rgb(248, 249, 251); */
  text-align: center;
  padding: 80px;
  padding-top: 60px;
}

.partner-para {
  color: #077cc8;
  margin-top: 0;
  font-family: KanitRegular;
  font-size: 40px;
}

.partner-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1140px;
  flex-wrap: wrap;
  margin: auto;
}

.partner-more-wd {
  margin: 15px 30px;
  width: max-content;
  flex: 0 0 auto;
}
.partner-more-wd img {
  width: 100%;
}

.last-logo {
  width: 8%;
}

.logo-last {
  width: 10%;
}

ul.enterbutton {
  float: left;
  padding: 0;
  margin: 53px 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

ul.enterbutton li {
  list-style: none;
  float: left;
  /* padding: 0 40px 0 0; */
}
.posre {
  position: relative;
}
ul.enterbutton li a {
  float: left;
  text-decoration: none;
  font-family: kanitRegular;
  border-radius: 40px;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.5s ease;
}

ul.enterbutton li:first-child a {
  padding: 8px 58px;
  background: #0950b5;
  color: #fff;
}

ul.enterbutton li:first-child {
  padding: 0 0 0 0;
}

ul.enterbutton li:nth-child(2) a {
  padding: 8px 70px;
  background-color: #e9f2ff;
  margin: 0 0 0 40px;

  color: #0950b5;
}

ul.enterbutton li:nth-child(2) {
  padding: 0;
}

ul.enterbutton li:first-child a:hover {
  background: #e9f2ff;
  color: #0950b5;
}

ul.enterbutton li:nth-child(2) a:hover {
  background: #0950b5;
  color: #ffffff;
}

.footer {
  /* display: flex; */
  padding: 25px;
}

.footer-img {
  margin: 20px 0 15px;
  width: 180px;
}

.footer-img img {
  width: 100%;
}

.copyright_sect {
  width: 36%;
  margin-right: 35px;
}

.copyright_sect p {
  font-family: MontserratRegular;
  font-size: 16px;
}

.footer-logo-div {
  display: flex;
}

.footer_ul_i {
  margin: 0px 10px;
}

.logo-footer a {
  background-color: #0950b5;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: 0.3s ease-in-out;
}

.logo-footer a svg {
  font-size: 22px;
}
/* .logo-footer a:hover {
  background-color: #e5ecf5;
  color: #0950b5;
} */

.footer ul {
  padding: 22px 0 0 0;
  margin: 0 30px 0 20px;
  list-style: none;
  line-height: 25px;
}

.ul_heading {
  font-family: MontserratBold;
  font-size: 16px;
  margin-bottom: 5px;
}

.copyrights p {
  color: #5f6471;
  font-family: MontserratRegular;
  font-size: 16px;
}

.copyright {
  width: 100%;
  float: left;
  background: #ffffff;
}

.shafimg {
  position: relative;
  bottom: -50px;
  right: 0;
}
body {
  margin: 0;
}
.header {
  width: 100%;
  position: fixed;
  background-color: white;
  z-index: 100;
  padding: 20px 0;
  display: flex;
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.1) !important;
}
a.btn {
  background: #0950b5;
  color: #fff !important;
  padding: 7px 18px;
  border-radius: 40px;
  transition: 0.3s ease-in-out;
}

.border-navbar {
  border-left: 1px solid #e9f2ff;
  height: 25px;
  position: relative;
  margin: 0px 17px 0 27px;
}

a.btn:hover {
  background: #e9f2ff;
  color: #0950b5 !important;
}

.shafimg img {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.shafimg img {
  width: 100%;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.partner-more-wd img.dark_mode:hover {
  filter: grayscale(0) !important;
}

.partner-more-wd img.light_mode:hover {
  filter: grayscale(0) !important;
}

/* Chef Page */

.chefmain {
  padding: 150px 0 120px;
  background: #F8F9FB;
}

.chefmain .col-xl-4 {width: 40%;}

.chefmain .textsection {
  padding-right: 10%;
}

.chefmain p {
  font-size: 20px;
}

.headngh1 {
  font-size: 60px;
  color: #0950B5;
  font-weight: 400;
  text-transform: capitalize;
}
.cheflist .row {
  flex-wrap: wrap;
  text-align: center;
}

.cheflist .row .teamsec {
  width: 25%;
  flex: 0 0 25%;
  padding: 43px;
  box-sizing: border-box;
  position: relative;
}


.cheflist {
  background: #F8F9FB;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;
}
.teamcon {
  background: #fff;
  padding: 95px 20px 15px;
  border-radius: 30px;
  margin-top: -125px;
  box-sizing: border-box;
}
/* .cheflist:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("images/Component 471.png");
  bottom: -0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  z-index: 0;
  opacity: 0.9;
} */

.cheflist {
  background-image: url("images/Component 471.png") !IMPORTANT;
  bottom: -0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  padding-bottom: 70px;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}
.teamcon h3 {
  font-size: 24px;
  color: #0950B5;
  margin-bottom: 0;
}
.cheflist .row .teamsec:hover img {
  top: 10px;
}

.cheflist .row .teamsec img {
  top: 0;
  position: relative;
  transition: all 0.5s ease;
  width: 100%;
}

.cheflist .row .teamsec:hover .teamcon {
  box-shadow: 0 7px 5px 2px rgb(0 0 0 / 13%);
}
.teamcon p {
  margin-top: 6px;
  color: #5F6471;
}
/* Chef Page */

@media all and (max-width: 767px) and (min-width: 320px) {
  .chefmain {
    padding: 102px 0 30px;
}

.headngh1 {
    font-size: 24px;
}

.chefmain .col-xl-4 {
    width: 100%;
    padding-top: 23px;
}

.chefmain p {
    font-size: 16px;
}

.chefmain .row {
    display: flex;
    flex-direction: column-reverse;
}

.chefmain .textsection {
    padding: 0;
}

.cheflist .row .teamsec {
    width: 100%;
    flex: 0 0 100%;
    padding: 16px;
}

.teamcon h3 {
    font-size: 18px;
}

.teamcon p {
    font-size: 14px;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 59px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-bottom: 15px;
    padding-top: 10px;
  }

  .nav-item {
    padding: 0px;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-bottom: 15px;
    padding-top: 10px;
  }

  .nav-link {
    text-align: center;
    padding: 10px 0px;
    width: 100%;
    display: table;
    font-size: 16px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: unset;
    right: unset;
    transform: none;
    font-size: 20px;
    cursor: pointer;
  }

  .fa-times {
    color: #0950b5;
    font-size: 24px;
  }
  .container {
    width: 80%;
    margin: auto;
  }
  ul.enterbutton {
    display: flex;
    justify-content: center;
    float: left;
    margin-top: 15px;
    padding-left: 0px !important;
    z-index: 99;
    position: relative;
  }
  
.middle-list {
  justify-content: space-between;
}
  .col-xl-6.col-lg-6.col-md-6.col-sm-12.col-12 {
    width: 100%;
  }
  .pricebutton {
    padding: 15px 0 0 0;
  }
  .row {
    display: block;
  }

  .banner-footer ul li {
    font-size: 13px;
    margin: 0px 15px;
  }

  ul.visitbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    float: none !important;
    padding-left: 0px;
    background: none;
  }
  ul.visitbutton li {
    padding: 0px 0 0 0px;
  }
  .pricebutton h1 {
    font-size: 36px;
    line-height: 42px;
    margin: 14px 0 0 0;
  }
  .pricebutton h6 {
    font-size: 34px;
    float: none;
    line-height: 40px;
    padding-right: 0;
    width: 100%;
  }
  .pricebutton p {
    font-size: 14px;
    line-height: 20px;
    color: #5f6471;
    padding: 0 12px 0 0px;
    float: none;
    width: 100%;
  }
  ul.enterbutton li:nth-child(2) a {
    padding: 8px 40px;
    margin: 0px 0 0 0;
  }
  ul.enterbutton li:nth-child(2) {
    padding: 0 0 20px 0;
    /* float: left; */
    /* width: 50%; */
  }
  ul.enterbutton li:first-child {
    padding: 0 0 20px 0;
  }

  ul.enterbutton li:first-child a {
    padding: 8px 22px;
  }

  ul.visitbutton li:first-child {
    background: #0950b5;
    padding: 3px 20px !important;
    margin: 0 14px 0px -22px;
  }

  ul.visitbutton li a {
    font-size: 16px;
    white-space: nowrap;
  }

  .shafimg {
    bottom: -25px;
    width: 100%;
    right: 0;
    left: 0;
    margin: auto;
    float: none;
  }

  .header {
    padding: 15px 0 10px 0;
    border-bottom: 1px solid #e5ecf5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shafimg2 {
    margin: auto;
    width: 65%;
    display: flex;
    align-items: center;
    margin-left: 75px;
  }
  button#togglebutton {
    background: none;
    border: none;
    width: 47px;
    padding: 4px 15px;
    outline: none;
  }
  button#togglebutton img {
    filter: invert(20%) sepia(34%) saturate(6724%) hue-rotate(210deg)
      brightness(88%) contrast(93%);
  }

  ul.enterbutton li {
    white-space: nowrap;
  }

  ul.visitbutton li.ancol {
    white-space: nowrap;
    background-color: #e9f2ff;
    padding: 3px 20px 3px 35px !important;
    margin-left: -35px;
    z-index: -1;
  }

  .border-navbar {
    display: none;
  }

  .switch {
    margin: auto;
  }

  .blog-img {
    width: 150px;
    height: 150px;
  }

  .blog-div h3 {
    font-size: 22px !important;
  }

  .blog-div p {
    font-size: 14px;
  }

  .blog-div a {
    font-size: 16px;
  }

  .main-para {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .logo-contact {
    width: 55px;
    height: 55px;
  }

  .partner {
    padding: 25px;
  }

  .partner-para {
    font-size: 30px;
  }

  .contact-div {
    width: auto;
  }

  .logo-contact {
    width: 55px;
    height: 55px;
  }

  .contact-div .total-follow {
    font-size: 30px;
  }

  .banner-footer ul {
    display: block;
    padding: 0 20px;

  }

  .contact-div .follow-txt {
    font-size: 14px;
  }

  .partner-para {
    font-size: 30px;
  }

  .partner-logo {
    width: 100% !important;
  }

  .logo-footer a svg {
    font-size: 20px;
  }

  .soclcen {
    width: 100%;
    display: block !important;
  }

  .blog-back .container {
    display: block;
  }

  ul.enterbutton li a {
    font-size: 16px;
    margin-right: 25px;
  }

  .banner:before {
    background-size: inherit;
  }

  .partner-more-wd {
    margin: 0px;
  }

  .daomaker {
    width: 38%;
  }

  .kenetic {
    width: 33%;
    margin: 0px 10px;
  }

  .lc {
    width: 16%;
  }

  .m6 {
    width: 22%;
    margin-top: 10px;
  }
  .ngc {
    width: 30%;
    margin: 10px 10px 0px;
  }

  .au21 {
    width: 32%;
  }

  .polygon {
    width: 38%;
    margin-top: 10px;
  }

  .qcp {
    width: 38%;
    margin: 10px 13px 0px;
  }

  .sl2 {
    margin-top: 10px;
    width: 25%;
  }

  .unilayer {
    width: 28%;
    margin: 10px 14px 0px !important;
  }

  .xend {
    width: 28%;
    margin-top: 10px;
  }

  .huobi_wallet {
    width: 45%;
    margin-top: 10px;
    margin-right: 10px;
  }

  .defibox {
    width: 36%;
    margin-top: 10px;
    margin-left: 10px;
  }

  .footer {
    display: block !important;
  }

  .copyright_sect {
    width: 100%;
    margin-right: 0px;
  }

  .copyright_sect p {
    font-size: 14px;
  }

  .copyright_sect p.p_1 {
    margin-bottom: 0px;
  }
  .copyright_sect p.p_2 {
    margin-top: 0px;
  }

  .footer ul {
    margin: 0 50px 0 0px;
    line-height: unset;
  }

  .footer ul li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 440px) {
  ul.visitbutton li.ancol {
    padding: 3px 6px 3px 28px !important;
  }
}

@media all and (max-width: 768px) and (min-width: 667px) {
  .switch {
    top: 1px !important;
  }
  .border-navbar {
    top: 5px !important;
  }
  .container {
    width: 73% !important;
  }

  .navbar {
    width: 100%;
  }

  .nav-menu {
    width: 100%;
    margin: 0px;
  }

  .nav-item {
    padding: 0 14px 0 0;
    white-space: nowrap;
  }

  .nav-item a.btn {
    margin-left: 10px;
  }
}
@media all and (max-width: 1023px) and (min-width: 768px) {
  .switch span svg {
    font-size: 16px !important;
}

.blog-div p {
    font-size: 14px;
}

.blog-div h3 {
    font-size: 18px;
}

.blog-img {
    width: 150px;
    height: 150px;
}

.blog-img img {
    width: 69%;
}
  a.nav-link {
    font-size: 13px;
}

.nav-menu {
    align-content:  flex-end !IMPORTANT;
    justify-content: flex-end !important;
    margin-left: auto;
}

.switch .dark-span {
    width: 20px;
    height: auto;
    margin: auto;
    top: 0px;
    padding: 3px;
}

.switch .light-span {width: 20px;height: auto;margin: auto;padding: 3px;top: 0;}

.switch {
    width: 52px;
    display: flex;
    align-items: center;
    height: 23px;
    justify-content: center;
}

.nav-item:last-child {
    padding-right: 0;
}

.switch span svg {
    font-size: 16px !important;
}
  .headngh1 {
    font-size: 28px;
}

.chefmain p {
    font-size: 14px;
}

.chefmain .col-xl-8 {
    width: 60%;
}

.chefmain .row {
    flex-direction: row;
}

.chefmain .shafimg {
    width: 100%;
    border: 0;
    top: 0;
}

.chefmain {
    padding: 90px 0 30px;
}

.cheflist .row {
    flex-direction: row;
}

.cheflist .row .teamsec {
    flex: 0 0 33%;
    padding: 14px;
}

.teamcon h3 {
    font-size: 18px;
}

.teamcon p {
    font-size: 14px;
}

.teamcon {
    padding-top: 101px;
    padding-bottom: 2px;
}
  .banner {
    padding-top: 145px !important;
  }

  .shafimg2 {
    width: 25%;
  }

  .pricebutton {
    padding: 30px 0 0 0px !important;
    text-align: center;
  }

  .pricebutton h6 {
    padding-right: 0 !important;
  }

  .shafimg {
    bottom: -55px;
    width: 60%;
    right: 0;
    left: 0;
    margin: auto;
  }

  .container {
    width: 80%;
    margin: auto;
  }

  ul.visitbutton {
    float: none;
    display: flex;
    justify-content: center;
    width: auto;
    background: none;
  }

  ul.visitbutton li {
    float: none;
  }

  ul.visitbutton li.ancol {
    background-color: #e9f2ff;
    margin-left: -23px;
    z-index: -1;
    padding-left: 40px;
  }

  .border-navbar {
    margin: 0px 14px 0 10px;
  }

  .col-xl-6.col-lg-6.col-md-6.col-sm-12.col-12 {
    width: 100%;
  }

  a.btn {
    padding: 4.4px 17px;
  }

  ul.visitbutton li {
    padding: 3px 21px 3px 23px;
  }

  ul.visitbut5ton {
    width: 37%;
  }

  ul.visitbutton li a {
    font-size: 16px;
  }

  .pricebutton h1 {
    font-size: 46px;
    line-height: 57px;
    width: 60%;
  }

  .pricebutton p {
    font-size: 16px;
    width: 100%;
    padding: 0;

  }

  ul.enterbutton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 21px 0 0 0;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ul.enterbutton li:nth-child(2) a {
    margin: 0 0 0 17px;
  }

  ul.enterbutton li:first-child a {
    padding: 5px 38px;
    font-size: 16px;
  }

  ul.enterbutton li:nth-child(2) a {
    padding: 5px 38px;
    font-size: 16px;
  }

  ul.enterbutton li a img {
    width: 83%;
    margin: 0 0px -18px 0;
  }

  .blog-back .container {
    display: flex;
    flex-wrap:wrap ;
  }

  .blog-back .container > div {
    flex: 0 0 50%;
}
  .partner-logo {
    width: 100%;
  }

  .footer ul li {
    /* padding: 0 30px 0 0;
    font-size: 15px; */
  }
}

@media all and (max-width: 1249px) and (min-width: 1120px) {
  ul.visitbutton {
    width: 85% !important;
  }
}

@media all and (max-width: 1249px) and (min-width: 1120px) {
  ul.visitbutton {
    width: 77% !important;
  }
}

@media all and (max-width: 1310px) and (min-width: 1250px) {
  ul.visitbutton {
    width: 70% !important;
  }
}

@media all and (max-width: 1365px) and (min-width: 1024px) {
  .nav-link {
    font-size: 16px;
}
  .chefmain {
    padding: 120px 0 45px;
}

.pricebutton h6 {
  font-size: 50px;
}
.blog-div p {
  font-size: 14px;
}
.chefmain .row {
}

.chefmain .col-xl-4 {
    width: 30%;
}

.chefmain .col-xl-8 {
    width: 70%;
}

.headngh1 {
    font-size: 36px;
}

.chefmain p {
    font-size: 18px;
}

.chefmain .shafimg {
    bottom: 0;
    top: 0;
}

.cheflist .row .teamsec {
    padding: 0 25px;
    margin-bottom: 25px;
}

.cheflist {
    padding-bottom: 18px;
}


  .blog-back .container > div {
    flex: 0 0 33%;
}

.blog-back .container {
    flex-wrap: wrap;
}
  .banner {
    padding-top: 125px !important;
  }
  .shafimg {
    position: absolute;
    width: 85%;
    bottom: -180px;
    right: 30px;
    margin: auto;
  }
  .row {
    /* flex-direction: column; */
  }

  .pricebutton h6 {
    padding-right: 0% !important;
  }

  .pricebutton h1 {
    width: 70%;
  }
  .pricebutton p {
    width: 95%;
  }

  ul.enterbutton {
    float: left;
    padding: 0;
    margin: 27px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .blog-img {
    width: 140px;
    height: 140px;
    padding: 10px;
  }
  .blog-div {
    width: 95%;
  }
  .inc-wdth {
    max-width: 80% !important;
  }
  .partner-logo {
    width: 100%;
  }
  .footer ul li {
    /* padding: 0 0 0 36px; */
  }
}

